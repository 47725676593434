import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import DataTable from "subComponents/Tables/DataTable";
import subCategoryTableData from "./data/subCategoryTableData";
import { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDelete from "../../../shared/deleteDialog";
import {
  useLazyGetSubCategoryQuery,
  useDeleteSubCategoryMutation,
} from "../../../redux/api/subCategoryApi";
import { getSubCategoryAction } from "../../../redux/slices/subCategorySlice";
import useFetchData from "../../../utility/useFetchData";
import useFilter from "utility/useFilter";
import { useDebounce } from "@uidotdev/usehooks";
import { customToastStyle } from "../../../redux/api";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function SubCategoryIndex() {
  const navigate = useNavigate();
  const [getSubCategory] = useLazyGetSubCategoryQuery();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [page, setPage] = useState(1);
  const [deleteSubCategory, { isLoading: isDeleteSubcategoryLoading }] =
    useDeleteSubCategoryMutation();
  const [deleteIndex, setDeleteIndex] = useState(null);
  const fetchSubCategoryData = useFetchData(getSubCategory, getSubCategoryAction);
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const { filters, setFilters } = useFilter();

  const { columns, rows } = subCategoryTableData(subCategoryList, (item) => setDeleteIndex(item));

  useEffect(() => {
    setFilters((prevValue) => ({ ...prevValue, name: debouncedSearchTerm, page }));
  }, [debouncedSearchTerm, page]);

  const handlePlanClick = () => {
    navigate("/yajyaManagement/yajya/add-yajya");
  };

  const handleCloseDelete = () => {
    setDeleteIndex(null);
  };
  const handleChange = (e, p) => {
    setPage(p);
  };

  const confirmDeleteHandler = async () => {
    if (deleteIndex !== null) {
      try {
        const deleteSubcategoryResponse = await deleteSubCategory(deleteIndex.id).unwrap();
        if (!deleteSubcategoryResponse?.success) {
          toast.error(`${deleteSubcategoryResponse.data.message || "Something went wrong!"} `, {
            style: customToastStyle.error,
          });
        }
        setDeleteIndex(null);
      } catch (err) {
        console.error("Failed to delete subcategory:", err);
      }
    }
  };

  useEffect(() => {
    const fetchCategoryData = async () => {
      const categoryData = await getSubCategory(filters);
      if (categoryData?.data?.success) setSubCategoryList(categoryData?.data?.data);
    };
    fetchCategoryData();
  }, [filters, isDeleteSubcategoryLoading, page]);

  return (
    <div>
      <div className="flex justify-end">
        <Button onClick={handlePlanClick} variant="addButton">
          Add Yajya&apos;s
        </Button>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                style={{
                  backgroundColor: "#FD8D4C",
                  color: "#FFFFFF",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Yajya&apos;s
                </MDTypography>
                <input
                  className="text-sm mr-2 p-2 text-gray-700 rounded-md w-56"
                  type="text"
                  placeholder="Search Here"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {deleteIndex !== null && (
        <ConfirmDelete
          title=""
          fullMessage={"Are you sure want you to Delete this Sub-Category?"}
          handleClose={handleCloseDelete}
          deleteHandler={confirmDeleteHandler}
          open={deleteIndex !== null}
        />
      )}
      <Typography variant="paginationAlign" component="div">
        <Stack spacing={2}>
          <Pagination
            count={subCategoryList.totalPages}
            defaultPage={page}
            onChange={handleChange}
            variant="outlined"
          />
        </Stack>
      </Typography>
    </div>
  );
}

export default SubCategoryIndex;
